@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.syncfusion.com/ej2/material.css");

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
}

/* hide number increase decrease button */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}

.tab-active {
  background-image: linear-gradient(to left, #7a59fd, #504ff9) !important;
  color: #fff !important;
  border: none !important;
}

.dashboard-username {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to left, #6711f1, #1a5fad);
  text-shadow: 0 5px 6px rgb(0, 0, 0, 0.4);
}
